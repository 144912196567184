export const VERSION_WEB = "1.0.0";

export const WEB_FILE_URL = "https://a-family-backoffice-api.makersoft.co.th/";
// export const WEB_FILE_URL = "https://localhost:7045/";
export const WEB_API = "https://a-family-backoffice-api.makersoft.co.th/api/";
// export const WEB_API = "https://localhost:7045/api/";
// export const WEB_API = "https://areeya-repair-backoffice-api.makersoft.co.th/api/";
// export const WEB_FILE_URL = "https://areeya-repair-backoffice-api.makersoft.co.th/";
// export const WEB_API = "https://aservice-cms-api.areeya.co.th/api/";
// export const WEB_FILE_URL = "https://aservice-cms-api.areeya.co.th/";
// export const WEB_API = "https://aservice-cms-api-test.areeya.co.th/api/";
// export const WEB_FILE_URL = "https://aservice-cms-api-test.areeya.co.th/";